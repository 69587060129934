import React from "react";
import { alertIcon } from "../../../../../assets";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../../slices/modals";

const Reminder = () => {
  const dispatch = useDispatch();
  return (
    <div className="reminder-container">
      <div className="reply-card-option">
        <span>Setup Your Reminder</span>
      </div>
      <div className="reminder-content">
        <div className="reminder-title">
          <span>Remind Me:</span>
        </div>
        <div className="reminder-description">
          <span>In 1 hour</span>
        </div>
      </div>
      <div
        className="reminder-content"
        onClick={() =>
          dispatch(
            openModal("info-modal", {
              id: "setup-reminder-modal",
              icon: alertIcon,
              title: "Let’s Setup your Reminder",
              description: "this reminder will help you to remember a task",
              btn_text: "Setup",
            })
          )
        }
      >
        <div className="reminder-title">
          <span>Setup:</span>
        </div>
        <div className="reminder-description">
          <span>Personalise time & date</span>
        </div>
      </div>
    </div>
  );
};

export default Reminder;
