import { IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { trashIcon } from "../../../../assets";
import { FilterCardsMessagesButton, Shortcuts } from "../../../../components";
import {
  changeFile,
  isShortcutFilterCard,
} from "../../../../slices/newMessage";
import data from "./../../../../utilities/constants";
import ChatBoxInput from "./ChatBoxInput";
import Reminder from "./Reminder";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
const ReplyZone = ({ user, firstMessage }) => {
  const dispatch = useDispatch();
  const [activeFilterCardsMessagesButton, setActiveFilterCardsMessagesButton] =
    useState({
      id: data.REPLIES_CONTROLLERS[0].id,
      text: data.REPLIES_CONTROLLERS[0].text,
    });
  const { clientConversationStored, statusClientConversation } = useSelector(
    (state) => state.conversations
  );

  const { newMessage, file, isshortcut } = useSelector(
    (state) => state.newMessage
  );
  const { t } = useTranslation();
  useEffect(() => {
    file !== null && dispatch(changeFile());
    activeFilterCardsMessagesButton.text !== "Reply" &&
      setActiveFilterCardsMessagesButton({
        id: data.REPLIES_CONTROLLERS[0].id,
        text: data.REPLIES_CONTROLLERS[0].text,
      });
  }, [clientConversationStored]);
  return (
    (firstMessage ||
      (((Object.keys(clientConversationStored)?.length !== 0 &&
        user?.role?.code === "OPERATOR" &&
        user?._id === clientConversationStored?.assigned?.user?._id) ||
        ["ADMIN", "SUPER-ADMIN"].includes(user?.role?.code)) &&
        statusClientConversation === "succeeded")) && (
      <div className="reply-zone-container">
        <div
          className={
            firstMessage
              ? "reply-cards-zone first-message-reply-cards"
              : "reply-cards-zone"
          }
        >
          {data.REPLIES_CONTROLLERS.map(
            (item, index) =>
              (index === 0 ||
                index === 3 ||
                (index === 1 && !firstMessage)) && (
                <FilterCardsMessagesButton
                  title={t(item.text)}
                  key={item.id}
                  id={item.id}
                  activeFilterCardsMessagesButton={
                    activeFilterCardsMessagesButton
                  }
                  onClick={() => {
                    setActiveFilterCardsMessagesButton({
                      id: item.id,
                      text: item.text,
                    });
                    dispatch(isShortcutFilterCard(item.text));
                  }}
                  chatBox
                />
              )
          )}
        </div>

        {activeFilterCardsMessagesButton.text === "Shortcuts" && (
          <Shortcuts
            newMessage={newMessage}
            firstMessage={firstMessage}
            user={user}
            replyZoneId="reply-zone-shortcuts"
          />
        )}
        {activeFilterCardsMessagesButton.text === "Reminder" && <Reminder />}
        {file && (
          <div className="file-container">
            <div className="file-name-container">
              <div>
                <ImageOutlinedIcon className="image-outlined-icon" />
              </div>
              <Typography className="file-name-text">{file.name}</Typography>
            </div>
            <IconButton onClick={() => dispatch(changeFile())}>
              <LazyLoadImage src={trashIcon} alt="delete-icon-image-upload" />
            </IconButton>
          </div>
        )}

        <ChatBoxInput
          activeFilterCardsMessagesButton={activeFilterCardsMessagesButton}
          setActiveFilterCardsMessagesButton={
            setActiveFilterCardsMessagesButton
          }
          user={user}
          isshortcut={isshortcut}
          newMessage={newMessage}
          file={file}
          firstMessage={firstMessage}
        />
      </div>
    )
  );
};

export default ReplyZone;
